:root {
    --offcanvas-width: 270px;
    --topNavbarHeight: 63px;
  }
  .sidebar-nav {
    width: var(--offcanvas-width);
  }
  .sidebar-link {
    display: flex;
    align-items: center;
  }
  .sidebar-link .right-icon {
    display: inline-flex;
  }
  .sidebar-link[aria-expanded="true"] .right-icon {
    transform: rotate(180deg);
  }
  .text-left {
    text-align: left;
  }
  
  .text-right {
    text-align: right;
  }
  
  
  @media (min-width: 992px) {
    body {
      overflow: auto !important;
    }
    main {
      margin-left: var(--offcanvas-width);
    }
    /* this is to remove the backdrop */
    .offcanvas-backdrop::before {
      display: none;
    }
    .sidebar-nav {
      -webkit-transform: none;
      transform: none;
      visibility: visible !important;
      height: calc(100% - var(--topNavbarHeight));
      top: var(--topNavbarHeight);
    }
    
  }