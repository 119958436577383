/* .sidebar-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
  border-bottom: 1px solid #ccc;
  padding: 9px;
} */
.logo {
  width: 100px;
  /* margin-bottom: 10px; */
}

.sidebar-header h2 {
  color: #00ff00;
  /* Green text color */
  font-size: 24px;
  margin: 0;
}

.sidebar-menu {
  list-style: none;
  padding: 0;
  width: 100%;
}


.sidebar-footer {
  margin-top: auto;
  width: 100%;
  /* padding: 20px; */
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
  gap: 10px;
  color: #fff;
  margin-bottom: 50px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}


/* 
.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
} */

input:checked+.slider {
  background-color: transparent;
}

input:checked+.slider:before {
  transform: translateX(14px);
}

.offcanvas .form-check-input:checked {
  background-color: transparent;
  border-color: #ffffff;
}

.offcanvas .form-check-input:focus {
  box-shadow: none !important;
  border-color: #e1e1e1 !important;
}

.logout-btn:hover li {
  color: red !important;
}

.logout-btn {
  cursor: pointer;
}

.load-icon {
  font-size: 11px;
  height: 17px !important;
  width: 17px !important;
}

.sidebar_wrapper {
  height: 90vh;
  /* padding-top: 5px; */
  /* background-color: #333; */
  color: white;
  display: flex;
  flex-direction: column;
  gap: 1.8rem;
  /* justify-content: space-between; */
}

ul.menu-section {
  padding: 0;
  margin: 0;
  list-style: none;
}

.menu-section {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.menu-item.active.disabled{
  color:#b1b1b1 !important
}
.menu-item {
  padding: 12px 50px;
  cursor: pointer;
  position: relative;
  transition: all 0.3s ease;
  width: 100%;
  z-index:1;
}

.menu-item li {
  display: flex;
  gap: 10px;
  align-items: center;
}

.menu-item::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 0;
  opacity: 0; 
  transition: width 0.3s ease;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  z-index: -1; 
}

.menu-item:hover:not(.menu-item.active):before {
  width: 80%;
  opacity: 1;
}

.menu-item:not(.menu-item.active):hover {
  padding-left: 40px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  /* color: #333;  */
}

/* .menu-item.active {
  color: #000;
  background-color: #B6D4B6;
} */

.theme-wrapper {
  padding: 15px 50px;
  cursor: pointer; 
  width: 100%;
}

.btn-check:focus+.btn,
.btn:focus {
  outline: 0;
  box-shadow: none!important
}

.menu-item i,
.menu-item svg {
  font-size: 1.2rem;
}