body {
  font-size: 14px;
}

hr:not([size]) {
  height: 1px;
  background: #bebcbc !important;
}

.navbar-toggler_img {
  width: 42px;
  height: 42px;
  border: 2px solid #ccc;
  border-radius: 50%;
}

.spinner-border {
  border-right-color: white !important;
}

.coin_img {
  width: 25px;
  height: 25px;
}

.minHight_table {
  height: 340px;
  overflow-y: auto;
}

.btn {
  font-size: 14px;
}

.overflow-x-auto {
  overflow-x: auto;
  overflow-y: hidden;
}

.heading {
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  margin-bottom: 1rem;
  /* white-space: nowrap; */
  letter-spacing: 0.3px;
}

/* .custombg {
  font-size: 0.85rem
} */

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: transparent !important;
  /* border-bottom: 2px solid #02ff01; */
  /* padding: 0.5rem 0.5rem !important; */
  border-radius: unset !important;
}

.card {
  border-radius: 25px;
}

.login_input_grp .input-group-text,
.form-control {
  padding: 11px;
}

.form-control:focus {
  box-shadow: none;
  /* border: 1px solid transparent; */
}
.form-control.custombg:focus {
  background-color: #fff;
}

.divdermain {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}

.divider {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 600px;
}

.divider-text {
  margin: 0 15px;
  color: #dee2e657;
  font-size: 15px;
}

.divider::before,
.divider::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #dee2e657;
}

.custom-button {
  border: 1px solid #cccccc4d;
  border-radius: 9px;
  padding: 0.5rem;
  text-align: center;
  justify-content: center;
  display: flex;
  gap: 9px;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.custom-button:hover {
  transform: scale(1.1);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.text2 {
  color: #8e8e8e;
}

.logintext2 {
  text-align: center;
  margin: auto;
  font-size: 12px;
  max-width: 320px;
}

.customcard {
  min-height: 530px;
  height: auto;
}
.pwd-min-card {
  min-height: 400px;
  height: auto;
}

.logo_circle_inrx {
  width: 50px;
  height: 50px;
  margin: auto;
  background: #000;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}

.logincardmain {
  display: flex;
  justify-content: center;
  height: 100vh;
  align-items: center;
}

.login_container {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  gap: 2rem;
}

.login-card {
  padding: 20px;
}

.login-card h2 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 25px;
}

.steps {
  position: relative;
}

.step {
  display: flex;
  align-items: flex-start;
  margin-bottom: 45px;
  position: relative;
}

.step-icon {
  color: #fff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  margin-right: 10px;
  z-index: 1;
  position: relative;
  border: 2px solid #28a745;
}

.step-content {
  flex: 1;
}

.step-content p {
  margin: 0;
}

.app-buttons {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.app-button {
  background-color: #333;
  color: #fff;
  border: 1px solid #28a745;
  padding: 7px 12px;
  border-radius: 5px;
  text-decoration: none;
  font-size: 14px;
  white-space: nowrap;
}

.app-button:hover {
  background-color: #28a745;
  color: #fff;
}

.step:not(:last-child)::after {
  content: "";
  position: absolute;
  left: 14px;
  /* Align with step icon */
  top: calc(100% + 5px);
  /* Position below the step icon */
  height: 35px;
  /* Default height */
  width: 2px;
  background-color: #5c5c5ca8;
}

/* .step:first-child:not(:last-child)::after {
  height: 71px;
  top: 38px;
} */

.main-wrapper {
  display: flex;
}

.main-wrapper .left-side-container {
  width: 250px;
}

.main-wrapper .sec-wrapper {
  width: calc(100% - 250px);
}

.has-search .form-control-feedback {
  position: relative;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  left: 55px;
  line-height: 2.5rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}

.has-search .form-control {
  padding-left: 2.375rem;
  border-radius: 10px !important;
  max-width: 550px;
  margin: auto;
}

a {
  text-decoration: none;
}

.dashboard_banner {
  border-radius: 10px;
  padding: 45px 35px;
  background: #000;

  min-height: 250px;
  overflow: hidden;
  position: relative;
}
.dashboard_banner::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url(../public/images/Yamuna_bridge_Prayagraj.jpg) center/cover;
  filter: blur(4px);
  z-index: 1;
  opacity: 0.6;
}

.dashboard_banner h2,
.dashboard_banner .bannerbutton {
  position: relative;
  z-index: 2;
}

.dashboard_banner h2 {
  font-size: 25px;
  font-weight: 600;
  line-height: 35px;
}

.arc_circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 150px;
  background-image: url("/public/images/arc.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin: 26px auto;
}

.userprofile {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  object-fit: cover;
}

main .card {
  border-radius: 10px;
}

.user_per {
  background: #ccf888;
  color: #000000;
  padding: 5px;
  font-weight: 700;
  border-radius: 20px;
  font-size: 12px;
}

.user_arrowup {
  background: #ccf888;
  font-weight: 700;
  border-radius: 50%;
  font-size: 14px;
  height: 25px;
  width: 25px;
  text-align: center;
  padding: 3px;
}

.user_arrowup i {
  color: #000000 !important;
  font-weight: bold;
}

.bannerbutton {
  padding: 13px 15px;
  font-size: 15px;
  font-weight: 600;
}

.total-assets-card {
  background-color: #ccf888;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  /* width: 220px; */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
  margin-bottom: 25px;
}

.total-assets-card .card-icon {
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 50%;
  padding: 10px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.total-assets-card .card-icon svg {
  fill: #ffffff;
  width: 24px;
  height: 24px;
}

.total-assets-card .amount {
  font-size: 1.8rem;
  font-weight: bold;
  color: #000;
  /* margin-top: 20px; */
}

.total-assets-card .text-muted {
  font-size: 1rem;
  color: #000;
}

.asset-text {
  color: #000;
}

.bg_card1 .card-icon1 {
  background-color: #ccf888;
}

.bg_card2,
.card-icon2 {
  background-color: #c1bffd;
}

.bg_card3,
.card-icon3 {
  background-color: #232c2d;
}

.bg_card3 .amount {
  color: #fff;
}

.bg_card3 .asset-text {
  color: #fff;
}

.dashbord-card .nav-pills .nav-link {
  border-radius: 50px !important;
  padding: 5px 20px !important;
  font-size: 14px;
}

/* .Buydiv label {
  font-weight: 600;
} */

.buybtn {
  border: none;
  padding: 15px;
  /* font-weight: 600; */
}

.Buydiv .form-control {
  padding: 15px;
}

.inricon {
  padding: 10px 19px;
  border-radius: 3px;
}

.imgicon2 {
  padding: 10px 5px;
}

.account_main {
  height: 100vh;
}

.top_img {
  position: relative;
  top: 42px;
  left: -52px;
}

.top_img1 {
  position: relative;
  top: 31px;
  left: -235px;
}

.top_img2 {
  position: absolute;
  bottom: 0px;
  right: 0px;
  z-index: 11;
}

.top_img3 {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 2;
}

.account_main h1 {
  font-weight: 600;
  font-size: 33px;
  position: absolute;
  top: 100px;
  margin-left: 20px;
}

.main-section {
  position: relative;
  top: -37px;
}

.main-section img {
  width: 130px;
}

.accout_div {
  display: inline-block;
  color: white;
  font-size: 14px;
  padding: 2px 2px;
  border: 1px solid transparent;
  border-radius: 12px;
  background-image: linear-gradient(#191620f0, #191620),
    linear-gradient(175deg, #840840 0%, #deb83b 35%, #95ba46 67%, #5b9352 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: none;
}

.account_main h3 {
  font-weight: 600;
  font-size: 25px;
}

.custombtn2 {
  background: linear-gradient(
    102deg,
    #840840 0%,
    #deb83b 35%,
    #95ba46 67%,
    #5b9352 100%
  );
  border: none;
  border-radius: 8px;
  color: #fff;
}

.custombtn2:hover {
  color: #fff;
}

.account_main .card-header {
  border-radius: 0px 0px 10px 10px;
}

.coin_style {
  width: 38px;
  height: 38px;
  background: #1c1c1e;
  border-radius: 50%;
  /* padding: 5px 6px; */
  text-align: center;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
}

.coin_style2 {
  width: 38px;
  height: 38px;
  background: #000;
  border-radius: 50%;
  /* padding: 5px 6px; */
  text-align: center;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
}

.coin_style3 {
  width: 38px;
  height: 38px;
  background: #02ff01;
  border-radius: 50%;
  padding: 5px 6px;
  text-align: center;
  position: relative;
  box-shadow: rgb(31 216 26) 0px 0px 29px 0px;
}

.coin_style4 {
  /* width: 25px;
  height: 25px;
  background: #000;
  border-radius: 50%;
  padding: 1px;
  text-align: center;
  position: relative; */
}

.coin_style4 img {
  width: 18px;
}

.coin_style img {
  width: 25px;
  height: 25px;
}

.coin_style2 img {
  width: 25px;
  /* height: 25px; */
}

.custom_table thead,
tbody {
  text-align: end;
}

.managment_section tbody,
.activity_section tbody {
  text-align: start !important;
}

.managment_section thead,
.activity_section thead {
  text-align: start !important;
}

.custom_activity_table thead,
tbody,
.identy_tbl tbody,
thead,
tbody {
  text-align: end !important;
  font-size: 14px;
}

.assets_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.status-offline {
  background: #d5dae0;
  position: absolute;
  /* z-index: 1111111; */
  border: #000;
  bottom: 0;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  right: 0;
}

.status-online {
  background: #20b26c;
  position: absolute;
  /* z-index: 1111111; */
  border: #000;
  bottom: 0;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  right: 0;
}

.custom_activity_table tbody tr {
  text-align: end;
}

.text-overlay {
  position: absolute;
  /* left: 50%; */
  transform: translate(23%, -142%);
}

.support_main .accordion-button::after {
  background-image: unset !important;
  content: "\f078";
  /* Unicode for Font Awesome chevron-down */
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  /* Font Awesome solid style */
  font-size: 1rem;
  margin-left: auto;
}

.sec_inr .accordion-button::after,
.sec_inr .accordion-button:not(.collapsed)::after {
  background-image: unset !important;
  font-family: "Font Awesome 5 Free";
  content: "\f067" !important;
}

.support_main .accordion-button:not(.collapsed)::after {
  content: "\f077";
  /* Unicode for Font Awesome chevron-up */
}

.support_main .accordion-item {
  border: unset !important;
}

.mob-font {
  font-size: 13px;
}

.accordion-button:focus {
  box-shadow: none !important;
  border: none !important;
}

.support_input .form-control {
  min-width: 260px;
  text-align: center;
  border-radius: 50px !important;
  border: 1px solid #02ff0159 !important;
}

.accordian_sec {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.text_style {
  font-weight: 600;
}

.icon_bg {
  width: 38px;
  height: 38px;
  background: #fff;
  border-radius: 50%;
  padding: 0px;
  text-align: center;
  position: relative;
}

.icon_bg i {
  color: #000;
  font-size: 26px;
}

.setting_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.setting_header2 {
  display: flex;
  align-items: center;
  gap: 15px;
}

.setting_header .btn {
  /* border: 1px solid #6666667a; */
}

/* 
.setting_header .btn:hover {
  color: #28a745 ;
} */

.modal-content {
  border-radius: 15px !important;
}

.modal-content .input-group .form-control {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.custom_success {
  background-color: #02ff01 !important;
}

.log-card {
  text-align: center;
}

.managment_section tbody tr:last-child td {
  border: none;
  /* Remove all borders for the last row in the tbody */
}

.managment_section thead tr th,
.activity_section thead tr th {
  padding: 15px 12px !important;
  border-bottom-width: 0;
}

.managment_section tbody tr td,
.activity_section tbody tr td {
  padding: 15px 12px !important;
}

.managment_section .table > :not(:first-child),
.activity_section .table > :not(:first-child),
.global_table .table > :not(:first-child) {
  border-top: none !important;
}

.tbl-head tr {
  border-bottom: 1px solid transparent !important;
}

/* .form-check-input:checked {
  background-color: #242424;
  border-color: #242424;
} */

.form-check-input:focus {
  box-shadow: none !important;
}

.custom_check .form-switch .form-check-input {
  width: 4em;
  height: 2em;
}

.verfication_alert {
  padding: 25px 40px;
  border-radius: 20px;
}

.flex_row {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
}

.flex_row_center {
  display: flex;
  align-items: center;
}

.viewmore:hover {
  color: #02ff01 !important;
  cursor: pointer;
  transition: all ease-in 0.3s;
}

.text-green {
  color: #02ff01;
}

.earn_data {
  padding: 1rem 0;
  font-size: 18px;
  font-weight: 600;
}

.button_grid {
  display: flex;
  gap: 15px;
  /* justify-content: center; */
}

.earn_btn {
  padding: 5px 40px;
}

.earn_btn:hover {
  background-color: #02ff01;
}

main {
  padding: 20px 40px 30px;
}

.staking .input-group .form-control {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.modal-header {
  border-bottom: 1px solid #dee2e61a;
}

.support_main .accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: unset;
  border-bottom-left-radius: unset;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 12.5px 14px !important;
  max-width: 100px;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  top: 4px !important;
}

/* .navbar-toggler_img{
  display: none ;
} */
@media (max-width: 992px) {
  /* .navbar-toggler_img{
    display: block !important;
  } */
  .button_grid {
    margin-top: 10px;
  }

  .earn_btn {
    padding: 5px 8px;
    font-size: 12px;
  }

  .earn_data {
    margin-bottom: unset;
    font-size: 15px;
  }

  .flex_row {
    display: grid !important;
  }

  .flex_row_center {
    display: grid !important;
    justify-content: center;
    align-items: center;
  }

  .m-mob-auto {
    margin: 0 auto;
    text-align: center;
  }

  .top_img,
  .top_img1,
  .top_img2,
  .top_img3 {
    display: none;
  }

  .account_main {
    padding-top: 70px;
  }

  .account_main h1 {
    position: unset !important;
    margin-bottom: 50px;
  }

  .assets_header {
    display: block !important;
    margin: 10px auto;
  }

  .custom_table thead,
  tbody {
    font-size: 14px;
    white-space: nowrap;
  }

  .accordian_sec {
    display: block;
  }

  .setting_header {
    display: block;
  }
}

.flex_row_between {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 768px) {
  .flex_row_between {
    display: grid;
    gap: 2rem;
    justify-content: space-between;
  }

  main {
    padding: 20px 10px 30px;
  }
}

@media (max-width: 992px) {
  .action_wrapper {
    margin-left: 54px;
  }

  .login_container {
    display: grid;
    gap: 2rem;
    padding: 2rem 0;
  }
}

@media (min-width: 992px) {
  .navbar-brand {
    min-width: 225px;
  }
}

.form-switch .form-check-input {
  padding: 12px;
  width: 3em !important;
}

.form-switch .form-check-input:focus,
.form-switch .form-check-input {
  background-image: url("/public/images/Dark.svg") !important;
}

.navbar-toggler:focus {
  box-shadow: none !important;
}

.setting_header2 > div:first-child {
  width: 40px;
}

ul.faqList {
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
}

ul.faqList li {
  line-height: 32px;
  position: relative;
  padding-left: 20px;
}

ul.faqList li::before {
  content: "\2713";
  position: absolute;
  left: 0;
  font-size: 14px;
  color: green;
}

ul.faqList li a {
  font-size: 14px;
  color: inherit;
  text-decoration: none;
}

.scrollbar::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: #0d0d0d;
}

.scrollbar::-webkit-scrollbar-thumb {
  background-color: #242424;
}

.custom_btn {
  background-color: #1bff1b;
  border-radius: 5px;
  /* box-shadow: rgba(0, 0, 0, 0.1) 0 2px 4px; */
  color: #000;
  font-weight: 600;
  cursor: pointer;
  display: inline-block;
  height: 44px;
  line-height: 44px;
  outline: 0;
  overflow: hidden;
  padding: 0 20px;
  pointer-events: auto;
  position: relative;
  text-align: center;
  touch-action: manipulation;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: top;
  white-space: nowrap;
  /* width: 100%; */
  z-index: 9;
  border: 0;
}

.custom_btn:hover {
  background: #1bff1b;
  color: #000;
}

.navbar {
  padding-bottom: 0.7rem !important;
}

.custom_btn_outline {
  background-color: transparent;
  /* border: 1px solid #1BFF1B; */
  border-radius: 5px;
  /* color: #fff; */
  cursor: pointer;
  display: inline-block;
  height: 44px;
  line-height: 44px;
  outline: 0;
  overflow: hidden;
  padding: 0 20px;
  pointer-events: auto;
  position: relative;
  text-align: center;
  touch-action: manipulation;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: top;
  white-space: nowrap;
  /* width: 100%; */
  z-index: 9;
  border: 0;
  transition: all 0.4s ease-in-out;
  font-weight: 600;
}

.custom_btn_outline:hover {
  background: #1bff1b;
  color: #000;
}

.custom_danger_btn {
  background-color: #e62143;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 2px 4px;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  height: 44px;
  line-height: 44px;
  outline: 0;
  overflow: hidden;
  padding: 0 20px;
  pointer-events: auto;
  position: relative;
  text-align: center;
  touch-action: manipulation;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: top;
  white-space: nowrap;
  /* width: 100%; */
  z-index: 9;
  border: 0;
}

.custom_danger_btn:hover {
  background: #e62143;
  color: #fff;
}

.button-outline {
  position: relative;
  z-index: 3;
  background: transparent;
  font-size: 14px;
  color: #fff;
  border-color: #cccccc;
  border-style: solid;
  border-width: 1px;
  border-radius: 6px;
  padding: 6px 20px;
  transition: all 0.2s linear;
}

.button-outline a {
  text-decoration: none;
}

.button-outline:hover {
  color: #000;
  transition: all 0.2s linear;
}

.img_50 {
  width: 50px;
}

.refer_heading {
  margin-top: 1rem;
}

.min-height-card {
  min-height: 190px;
}

@media only screen and (max-width: 480px) {
  .list_content {
    display: grid !important;
  }
  .setting_header2 {
    align-items: flex-start;
  }

  .display_row {
    grid-template-rows: repeat(3, auto) !important;
  }

  .full_banner {
    padding: 2.5rem 1rem !important;
  }

  /* .highlight_card {
    padding: 1rem 0.5rem !important;
  } */

  .banner_heading {
    font-size: 1.5rem !important;
    line-height: 2rem !important;
  }

  .hide_on_mobile {
    display: none;
  }

  .value_div {
    font-size: 30px !important;
  }

  .headingh6 {
    font-size: 14px;
  }

  .refer_heading {
    font-size: 16px !important;
  }

  .earn_heading {
    font-size: 1.4rem !important;
    line-height: 1.8rem !important;
  }

  .box_wrapper {
    display: grid !important;
  }

  .box-right .text-end {
    text-align: center !important;
  }

  .box_wrapper .box-left {
    text-align: center;
    align-items: center !important;
  }

  .total-assets-card .amount {
    font-size: 1.2rem;
    font-weight: bold;
    margin-top: 0;
  }

  .heading {
    font-size: 1.2rem;
  }
}

.verticle_line {
  border: none;
  flex-shrink: 0;
  background: rgb(105 96 96 / 28%);
  width: 1px;
  height: 1em;
  margin: 0px 12px;
  display: inline-block;
  vertical-align: middle;
}

.img_30 {
  width: 30px;
  margin-right: 10px;
  /* height: 30px; */
}

.img_35 {
  width: 30px;
  height: 30px;
  /* height: 30px; */
}

.img_25 {
  width: 20px;
  height: 20px;
}

.h-420 {
  height: 320px;
}

.box_wrapper {
  align-items: center;
  /* background: #fff; */
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
  padding: 8px;
  position: relative;
}

.box_wrapper .box-left {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 180px;
  padding-left: 24px;
}

.box_wrapper .box-right {
  /* background: #a1e6a33b; */
  border-radius: 8px;
  flex: 1 1;
  /* min-height: 126px; */
  padding: 24px 24px;
  position: relative;
}

.gift_icon_wrapper {
  bottom: 0;
  font-size: 3rem;
  opacity: 0.6;
  position: absolute;
  right: 7px;
  /* color: #23232321; */
  bottom: -14px;
}

.box_heading {
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  /* color: #000; */
  display: -webkit-box;
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.amount_heading {
  color: #335835;
  font-size: 1.3rem;
  font-weight: 600;
  line-height: 1.3rem;
  position: relative;
  z-index: 3;
  margin-bottom: 0;
}

.coupon_code {
  color: #81858c;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  position: relative;
  z-index: 3;
  margin-bottom: 0;
}

.go_button {
  background-color: transparent;
  border: 1px solid #d5dae0;
  border-radius: 8px;
  color: #121214;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  line-height: 24px;
  padding: 12px 32px;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.highlighted_card {
  /* background-color: #021f0294; */
  border-radius: 16px;
  padding: 16px 35px;
  align-items: center;
  cursor: pointer;
  margin-top: 24px;
  margin-bottom: 24px;
}

.highlighted_card_text {
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  color: #fff;
  /* max-width: 150px; */
}

.sub_description {
  font-size: 14px;
  line-height: 24px;
  color: #fff;
  /* max-width: 150px; */
}

.reward_img {
  width: 100px;
}

.corner_badge {
  background: #2bc532;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 11px;
  color: #fff;
  font-size: 12px;
  padding: 2px 6px;
  position: absolute;
  right: 0;
  top: 0;
}

.full_banner {
  border-radius: 16px;
  padding: 0.5rem 2rem;
  align-items: center;
  cursor: pointer;
  margin-top: 24px;
  margin-bottom: 3rem;
}

.banner_heading {
  font-weight: 600;
  font-size: 2.3rem;
  line-height: 2.6rem;
  color: #61ff68;
}

.value_div {
  font-size: 48px;
  color: transparent;
  background: linear-gradient(45deg, #335835, #a1e6a3);
  background-clip: text;
  -webkit-background-clip: text;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -2px;
  margin: 0 4px;
}

.spacer {
  padding: 3rem 0;
}

.text-color {
  color: #757272;
}

.dollar_icon {
  width: 60px;
  position: absolute;
  right: -10px;
  top: -14px;
  transform: rotateZ(324deg);
  opacity: 0.5;
}

.help-center-accordion button,
.help-center-accordion .accordion-body {
  /* background-color: #000;
  color: #fff; */
  padding: 1rem 0;
  font-size: 14px;
}

.help-center-accordion .accordion-button::after {
  background-image: unset !important;
  content: "\f078";
  /* Unicode for Font Awesome chevron-down */
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  /* Font Awesome solid style */
  font-size: 1rem;
  margin-left: auto;
}

.sec_inr .accordion-button::after,
.sec_inr .accordion-button:not(.collapsed)::after {
  background-image: unset !important;
  font-family: "Font Awesome 5 Free";
  content: "\f067" !important;
}

.help-center-accordion .accordion-button:not(.collapsed)::after {
  content: "\f077";
  /* Unicode for Font Awesome chevron-up */
}

.help-center-accordion .accordion-item {
  border-bottom: 1px solid #ccc !important;
}

ul.green_bulltes {
  margin: 0;
  padding: 0;
}

ul.green_bulltes li {
  list-style: none inside none;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 24px;
}

.no_list {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin-right: 10px;
}

.list_content {
  display: flex;
  justify-content: start;
  align-items: baseline;
}

.earn_heading {
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 2.6rem;
}

.border-divider {
  border-top: 1px dashed #404347;
  margin-top: 40px;
  margin-bottom: 40px;
}

.highlight_card {
  /* background-color: #1c1c1e; */
  padding: 2rem;
  border-radius: 10px;
}

.arrow_col {
  display: flex;
  align-items: center;
  justify-content: center;
}

.display_row {
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: repeat(2, auto);
}

.chat-image {
  max-width: 50%;
  /* Ensures the image doesn't exceed the container's width */
  height: auto;
  /* Maintains the aspect ratio */
  border-radius: 10px;
  /* Optional: Adds rounded corners to the images */
  object-fit: contain;
  /* Optional: Ensures the image covers the container */
}

.chat_custom-scrollbar::-webkit-scrollbar {
  width: 12px;
  border-radius: 10px;
  background-color: red;
}

.chat_custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #02ff01;
  border-radius: 10px;
  border: 3px solid lightgrey;
}

.chat_custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #02ff01;
}

.chat_custom-scrollbar::-webkit-scrollbar-track {
  background: #02ff01;
}

/* Firefox */
.chat_custom-scrollbar {
  scrollbar-width: thin;
  /* "auto" or "thin" */
  scrollbar-color: #0b4f0b lightgrey;
  /* thumb and track color */
}

.chat-refresh-round {
  animation: chat-refresh 1s linear infinite;
}

@keyframes chat-refresh {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.modal_select_options {
  display: flex;
  align-items: center;
  margin-top: 12px;
  border-radius: 8px;
  padding: 2px 15px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.modal_select_options.active {
  border: 2px solid #5ff15f;
}

.deposit_imgs {
  width: 42px;
  height: 42px;
  border: none;
  margin-right: 16px;
  object-fit: cover;
}

.content_div {
  flex: 1 1 0%;
  text-align: left;
  letter-spacing: 0.3px;
}

.form-select {
  padding: 0.775rem 2.25rem 0.375rem 0.75rem !important;
  font-size: 13px !important;
}

.form-select:focus {
  box-shadow: none !important;
}

.active_symbol {
  z-index: 0;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 0;
  height: 0;
  border-bottom: 32px solid #02ff01;
  border-left: 32px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}

.check_mark {
  position: relative;
  bottom: -21px;
  right: 10px;
  /* transform: translate(-50%, -50%); */
  margin: 0;
  padding: 0;
  z-index: 1;
  color: #000;
}

.input-file {
  border-radius: 4px;
  height: 40px;
  width: 100%;
}

.input-file::file-selector-button {
  border: none;
  border-top-left-radius: 4px;
  height: 40px;
  cursor: pointer;
  transition: all 0.25s ease-in;
  cursor: pointer;
}

.table_wrapper {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 16px;
}

.column_wrapper {
  display: block;
}

.data_vertical {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.custom-google-login {
  background: red !important; /* Replace with your desired color */
  color: #fff !important; /* Replace with your desired text color */
  border-radius: 5px; /* Example for rounded corners */
  padding: 10px 20px; /* Example for custom padding */
}

/* Optionally, customize the hover state */
.custom-google-login div:hover {
  background-color: #fff !important;
}

.nsm7Bb-HzV7m-LgbsSe .nsm7Bb-HzV7m-LgbsSe-Bz112c-haAclf {
  border-radius: 50% !important;
  width: 33px !important;
  height: 33px !important;
  min-width: 33px !important;
}

.fw-semibold {
  font-weight: 600 !important;
}
.custom_dropdown {
  padding: 0;
  position: absolute;
  top: 100%;
  left: 0;
  border-radius: 4px;
  z-index: 0;
  width: 100%;
}

@media (max-width: 480px) {
  .accor_timeline_line_check,
  .accor_timeline_no_check {
    display: none !important;
  }
  .customcard {
    min-height: auto;
    height: auto;
  }

  .table_wrapper {
    grid-template-columns: 1fr;
  }
}

.border-left-heading {
  position: relative;
  padding-left: 9px;
}

.border-left-heading::before {
  position: absolute;
  content: "";
  height: 90%;
  left: 0;
  top: 2px;
}

.custom-file-upload {
  width: 120px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 8px;
  cursor: pointer;
  position: relative;
}

.custom-file-upload i {
  font-size: 2rem;
  color: #999;
}

.custom-file-upload input[type="file"] {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

@media (max-width: 480px) {
  .nav-link {
    display: block;
    padding: 0.5rem 0.5rem;
  }

  .accor_timeline_no_check,
  .accor_timeline_no {
    margin-right: 0 !important;
    display: none !important;
  }

  .reward_img,
  .gift_icon_wrapper {
    display: none;
  }

  .box_wrapper .box-left {
    width: unset !important;
    padding-left: 0 !important;
    padding: 1rem;
  }
  .box_heading {
    text-align: center;
  }
}

@media (min-width: 768px) {
  .secondaryForm .form-control,
  .custombg,
  .secondaryForm .input-group-text,
  .form-control {
    font-size: 14px;
  }
}

.offcanvas-bottom {
  border-top-right-radius: 1.2rem;
  border-top-left-radius: 1.2rem;
  height: 450px;
}

.offcanvas-header {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

/* ========Accordion Style overrides ========*/
.accordion-item {
  margin-bottom: 5px;
}

.accordion-button {
  font-size: 14px;
}

button:focus:not(:focus-visible) {
  outline: 0;
  box-shadow: none !important;
}

.accordion-body {
  margin-bottom: 0;
}

.accordion-button::after {
  background-image: unset !important;
  content: "\f078";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 1rem;
  margin-left: auto;
}

.accordion-button:not(.collapsed)::after {
  content: "\f077";
}

.accordion-button:not(.collapsed) {
  box-shadow: none !important;
}

.custom-file-label {
  display: inline-block;
  cursor: pointer;
  padding: 8px 12px;
  background-color: #007bff; /* Button background color */
  color: #fff; /* Button text color */
  border-radius: 4px;
  border: 1px solid transparent;
  transition: background-color 0.3s, border-color 0.3s;
}

.custom-file-label:hover {
  background-color: #0056b3; /* Hover background color */
  border-color: #004085; /* Hover border color */
}

.custom-file-label:active {
  background-color: #004085; /* Active background color */
  border-color: #002752; /* Active border color */
}

.btn-success-green {
  background: #02ff01;
  border-color: #02ff01;
  color: black;
}

.menu-scroll-data {
  height: 450px;
  overflow: scroll;
}
.mobile_menu_wrapper {
  display: grid;
  gap: 0.5em;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  justify-items: center;
}
.mobile_menus {
  margin-bottom: 1rem;
  text-align: center;
}

.mobile_menu_item {
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
}

.menu_iconbg {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.3rem;
  margin: auto;
  position: relative; /* Positioning context for the child elements */
}

.inr-icon,
.inrx-icon {
  top: -10px;
  right: 0;
  position: absolute;
  width: 18px;
  height: 18px;
  background-color: #000;
  color: #55ff00;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu_text {
  text-align: center;
}

.agreement_box {
  position: relative;
  overflow: hidden; /* Ensure the watermark stays within the box */
}

.agreement_box::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: url(../public/images/blackinrx.png) no-repeat;
  background-size: 390px 200px;
  opacity: 0.1;
  width: 390px;
  height: 224px;
  pointer-events: none;
  z-index: 1;
}

.agreement_box > .container {
  position: relative;
  z-index: 2;
}

.agreement_box input[type="text"] {
  width: 10rem;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 1px solid #666;
  margin: 0 5px;
  background-color: transparent;
}

.agreement_box input[type="text"]:focus {
  box-shadow: 0;
  outline: 0;
}
.agreement-section {
  margin-top: 2rem;
}

#preview {
  position: relative;
  margin:15px 0;
  background-color: #f5f6f5;
  padding: 20mm;
  box-sizing: border-box;
  background: #fff;
  color: #000;
  width: 210mm;
  min-height: 247mm,
}


/* .watermark {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-30deg);
  font-size: 50px;
  color: rgba(0, 0, 0, 0.1);
  pointer-events: none;
} */
