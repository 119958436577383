.accordion {
    --bs-accordion-color: #212529;
    --bs-accordion-bg: transparent !important;
  }
  
  .timeline_container {
    display: flex;
    width: 100%;
    min-height: 87px;
    max-height: auto;
  }
  .accor_timeline_no {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    font-size: 16px;
    line-height: 24px;
    color: #222;
    text-align: center;
    border-radius: 50%;
    border: 1px solid #dcdee3;
    background-color: #f5f5f5;
    margin-right: 35px;
    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
    margin-top: 10px;
  }
  .accor_timeline_no_check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    font-size: 13px;
    line-height: 24px;
    color: #fff;
    border-radius: 50%;
    border: 1px solid #3dae18;
    background-color: #3dae18;
    margin-right: 35px;
    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
    /* margin-top: 5px; */
}
  .accor_timeline_line {
    margin-top: 0;
    margin-left: 14px;
    width: 1px;
    height: calc(100% - 20px);
    background: #dcdee3;
  }
  .accor_timeline_line_check {
    margin-top: 0;
    margin-left: 14px;
    width: 1px;
    height: calc(100% - 20px);
    background: #52d428;
  }
  .verification_accrodion .accordion-item {
    width: 100%;
    border: 0!important;
  }
  .verification_accrodion h2.accordion-header {
    line-height: 15px;
    font-size: 14px !important;
  }
  .asset_dropdown_wrapper {
    position: relative;
    overflow: visible;
  }
  .select_box {
    flex: 1 1 0%;
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    display: flex;
    border-radius: 4px;
    padding-left: 16px;
    padding-right: 16px;
    background-color: rgba(0, 20, 42, 0.04);
    -webkit-box-align: center;
    align-items: center;
    align-self: flex-start;
    height: 48px;
    cursor: pointer;
    pointer-events: auto;
  }
  .verification_accrodion .accordion-button:not(.collapsed) {
    color: #111111;
    background-color: transparent !important;
    box-shadow: none;
  }
  .verification_accrodion .accordion-button {
    display: inline-block;
  }
  .product_name {
    line-height: 20px;
    letter-spacing: 0.3px;
    font-size: 14px;
  }
  .asset_dropdown {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: auto;
  }
  .dropdown-toggle::after {
    display: none;
  }
  .asset_dropdown_menu {
    width: 100%;
    left: -16px !important;
    /* top: 12px !important; */
    padding: 20px;
    bottom: 100%;
    position: absolute !important;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border: 0;
    z-index: 100;
  }
.text-lgray {
  color: #7e7e7e !important;
}
.fs-12 {
  font-size: 12px !important;
}
.fs-13 {
  font-size: 13px !important;
}
.fs-14 {
  font-size: 14px !important;
}
.select_options {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 14px 6px;
  cursor: pointer;
  border-radius: 3px;
}
.market_coin_img {
  height: 30px;
  width: 30px;
  max-width: 30px;
  margin-right: 5px;
  border-radius: 50%;

  background: #0d0d0d;
  padding: 2px;
}
.market-details-table {
  table-layout: fixed;
  width: 100%;
}
.market-details-table td {
  border-bottom: none !important;
}
.market-details-table th {
  text-align: left;
  font-size: 13px;
}
.market-details-table td {
  flex-direction: row-reverse;
  padding: 5px;
  text-align: right;
  font-size: 13px;
}
.lrtcss-ifarjv p {
  font-size: 14px;
  font-weight: 600;
}

.sorting_btn {
  color: #84889499;
  font-size: 12px;
  position: absolute;
  top: -5px;
}

.sorting_btn .active {
  color: #02ff01 !important;
}

.global_table tbody tr {
  font-size: 14px;
  vertical-align: middle;
}

.global_table thead tr th {
  white-space: nowrap;
  font-size: 13.5px;
  font-weight: 500;

  color: rgba(0, 13, 29, 0.6);
  padding: 8px 12px;
  line-height: 35px;
  background: rgba(245, 245, 246, 0.6);
}

.global_table tbody tr td {
  border-right: none;
  border-left: none;
  color: rgb(0, 13, 29);
  line-height: 24px;
  background: rgb(255, 255, 255);
  padding: 10px 12px;
}
.no-data-found {
  display: inline-block;
  width: 120px;
  padding: 10px;
}
.table_scroll_div .table > :not(:first-child) {
  border-top: none !important;
}
.global_table tr {
  border-bottom: 1px solid transparent !important;
}
